import React, { useContext, useMemo, useState } from "react"
import styled from "styled-components"
import {
  containerMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import { BrinkContext } from "../context/BrinkContext"
import VariantGridItem from "./VariantGridItem"

const Title = styled.h4`
  grid-area: 1 / 1 / 1 / 17;
  padding-left: 1.2rem;
  margin: 0 0 2rem;
  text-transform: uppercase;
`

const Container = styled.div`
  width: 100%;
  max-width: ${containerMaxWidth};
  text-align: center;
  margin: 0 auto;
  background-color: ${(p) => p.theme.colors.white};

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.columns === "4" ? "100%" : `calc(100% / ${p.columns})`)};
  }
`

const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  padding: 0;

  > div {
    width: calc((100% / 2));
  }

  ${MEDIA_MIN_LARGE} {
    width: 100%;
    margin: 0;

    > div {
      width: calc(100% / 4);
    }
  }
`

const VariantGrid = ({ title, product, variants, columns, ...other }) => {
  const { getStocks } = useContext(BrinkContext)
  const [currentStock, setCurrentStock] = useState(null)

  useMemo(
    async () =>
      await getStocks(variants.map((variant) => variant._id)).then(
        (variants) => {
          setCurrentStock(variants)
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <Title>{title}</Title>
      <Container columns={columns} {...other}>
        <Grid>
          {variants.map((variant, index) => (
            <VariantGridItem key={index} product={product} variant={variant} />
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default VariantGrid
