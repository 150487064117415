import axios from "axios"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import * as events from "../../components/context/utils/events"
import { MEDIA_MEDIUM, MEDIA_MIN_MEDIUM } from "../../constants"
import useSanityTranslation from "../../hooks/useSanityTranslation"
import { LocalizedLink as Link } from "../LocalizedLink"
import { BrinkContext } from "../context/BrinkContext"
import Price from "./Price"
import { Stars } from "./page/Reviews"
import TextWithLineBreaks from "../ui/TextWithLineBreaks"
import { withoutBreaklines } from "../../helpers/string"

const Wrapper = styled.div`
  cursor: pointer;
  width: ${(p) => (p.slider ? "100%" : "calc(50%)")};

  &:hover {
    h3,
    .price span {
      font-variation-settings: "wght" 700, "wdth" 700;
    }
  }

  ${MEDIA_MIN_MEDIUM} {
    width: ${(p) => (p.slider ? "100%" : "calc(100% / 3)")};
  }

  ${MEDIA_MEDIUM} {
    width: calc(100% / ${(p) => p.columns});
  }
`

const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
`

const Image = styled(GatsbyImage)`
  margin: 0 auto;
  width: 100%;
  z-index: 11;
`

const ProductInfoContainer = styled.div`
  justify-content: space-between;
  align-items: self-end;
  display: ${(p) => (p.comingSoon ? "none" : "flex")};
  width: 100%;
  position: absolute;
  bottom: 0.7rem;
  z-index: 12;
  color: ${(p) => p.theme.colors.black};
`

const MobileNameContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  font-size: 1.6rem;
  font-variation-settings: "wght" 450, "wdth" 700;
  text-transform: initial;
  line-height: 1.8rem;
  text-align: left;
  order: 1;
  margin: 0;
  padding-left: 1.2rem;

  p {
    font-size: 1.6rem;
  }

  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }

`

const NameContainer = styled.h3`
  display: none;
  ${MEDIA_MIN_MEDIUM} {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    font-size: 2rem;
    line-height: 2.4rem;
    font-variation-settings: "wght" 450, "wdth" 700;
    text-transform: initial;
    text-align: left;
    order: 1;
    margin: 0;
    padding-left: 1.2rem;
  }
`

const PriceContainer = styled.div`
  display: flex;
  order: 2;
  padding-right: 1rem;
`

const RatingContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 1.2rem;
  z-index: 12;
  color: ${(p) => p.theme.colors.black};
`

const FormattedPrice = styled(Price)`
  font-size: 1.6rem;
  line-height: 1.4rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
`

const VariantGridItem = ({ variant, product, slider = false }) => {
  const trans = useSanityTranslation()
  const { languageCode, currentStore } = useContext(BrinkContext)
  const [reviewStats, setReviewStats] = useState(null)

  const price =
    variant.subscriptionInitialFee ??
    variant.subscriptionVariant?.price ??
    variant.price
  const shouldMultiply = !variant.subscriptionInitialFee

  useEffect(() => {
    if (variant._id) {
      axios
        .get("https://api.reviews.io/product/review", {
          params: {
            store: "comis.com",
            apikey: process.env.GATSBY_REVIEWS_IO_API_KEY ?? "",
            sku: variant._id,
            per_page: 1,
            page: 1
          }
        })
        .then((response) => {
          if (response?.data?.stats) {
            setReviewStats(response.data.stats) // average + count
          }
        })
        .catch((error) => {
          console.error("There was an error!", error)
        })
    }
  }, [variant._id])

  const onVariantClick = (variant) => {
    events.selectProduct(variant.displayName.en, "collection page")
  }

  return (
    <Wrapper slider={slider}>
      <Link
        onClick={() => {
          onVariantClick(variant)
        }}
        to={`/product/${
          product ? product.slug.current : variant.product?.slug.current
        }?variant=${variant.slug.current}`}
      >
        <ImageContainer>
          {reviewStats !== null ? (
            <RatingContainer>
              <Stars
                stars={Math.round(reviewStats.average)}
                count={reviewStats.count}
              />
            </RatingContainer>
          ) : null}
          <Image
            image={getImage(variant.mainImage.asset)}
            className="product-card-primary-image"
            alt={variant.displayName?.[languageCode] || variant.displayName?.en}
          />
          <ProductInfoContainer>
            <MobileNameContainer>
              <TextWithLineBreaks text={trans(variant.displayName)} />
            </MobileNameContainer>
            <NameContainer>{withoutBreaklines(trans(variant.displayName))}</NameContainer>
            <PriceContainer className="price">
              <FormattedPrice price={price} multiply={shouldMultiply} />
            </PriceContainer>
          </ProductInfoContainer>
        </ImageContainer>
      </Link>
    </Wrapper>
  )
}

export default VariantGridItem
