import React, { useContext } from "react"
import styled from "styled-components"
import { MEDIA_MIN_LARGE } from "../constants"
import Layout from "../components/Layout"
import { BrinkContext } from "../components/context/BrinkContext"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import VariantGrid from "../components/product/VariantGrid"
import WidgetLoader from "../components/widgets/WidgetLoader"
import { useTranslation } from "react-i18next"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: auto;
  grid-gap: 1.2rem;

  ${MEDIA_MIN_LARGE} {
    padding: 0;
  }
`

const DesktopHero = styled(GatsbyImage)`
  display: none !important;

  ${MEDIA_MIN_LARGE} {
    display: block !important;
    grid-area: 1 / 1 / 1 / 9;
    height: auto;
  }
`

const MobileHero = styled(GatsbyImage)`
  display: block !important;
  grid-area: 3 / 1 / 4 / 17;

  ${MEDIA_MIN_LARGE} {
    display: none !important;
  }
`

const Content = styled.div`
  grid-area: 1 / 1 / 1 / 17;
  padding: 0.5rem 1.2rem;

  ${MEDIA_MIN_LARGE} {
    display: block !important;
    grid-area: 1 / 9 / 1 / 17;
  }
`

const Title = styled.h1`
  font-size: 2.6rem;
  line-height: 3rem;
  font-variation-settings: "wght" 500;
  text-transform: initial;
  text-align: left;
  margin: 0;
`

const Description = styled.p`
  font-size: 2.6rem;
  line-height: 3rem;
  font-variation-settings: "wght" 500;
  text-transform: initial;
`

const CollectionSection = styled.section`
  grid-area: 2 / 1 / 2 / 17;
  display: flex;
  flex-direction: column;
  gap: 5.6rem;
  margin-bottom: 8rem;

  ${MEDIA_MIN_LARGE} {
    margin-bottom: 0;
  }
`

const Collection = styled.div`
  h2 {
    padding: 0 1.2rem;
    font-size: 2rem;
    font-variation-settings: "wght" 450;
  }
`

const WidgetWrapper = styled.div`
  grid-area: 3 / 1 / 3 / 17;
`

const WidgetTitle = styled.h4`
  margin: 6rem 0 2rem;
  padding: 0 1.2rem;

  span {
    margin-right: 0.5rem;
  }
`

const CollectionPage = ({ pageContext }) => {
  const { t } = useTranslation()
  const { languageCode } = useContext(BrinkContext)

  const collectionPage = pageContext.collectionPage
  const desktopHeroImage = collectionPage.desktopImage?.asset ?? null
  const mobileHeroImage = collectionPage.mobileImage?.asset ?? null
  const metaTitle = collectionPage.metaTitle[languageCode]
  const title = collectionPage.pageTitle[languageCode]
  const description = collectionPage.pageDescription[languageCode]

  const collections = collectionPage.collections ?? []
  const widgets = collections[0]?.variants[0]?.product?.widgets ?? []

  return (
    <Layout
      meta={{ title: metaTitle }}
      invertedHeader
      pageContext={pageContext}
    >
      <Container>
        {desktopHeroImage ? (
          <DesktopHero
            image={getImage(desktopHeroImage)}
            alt={desktopHeroImage?.altText ?? title}
          />
        ) : null}
        <Content>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Content>
        <CollectionSection>
          {collections.map((collection, index) => (
            <Collection key={index}>
              <h2>{collection.displayName[languageCode]}</h2>
              <VariantGrid variants={collection.variants} columns="4" />
            </Collection>
          ))}
        </CollectionSection>
        {mobileHeroImage ? (
          <MobileHero
            image={getImage(mobileHeroImage)}
            alt={mobileHeroImage?.altText ?? title}
          />
        ) : null}
        {widgets?.length > 0 && (
          <WidgetWrapper>
            <WidgetTitle>{t("Learn more")}</WidgetTitle>
            <WidgetLoader widgets={widgets} />
          </WidgetWrapper>
        )}
      </Container>
    </Layout>
  )
}

export default CollectionPage
