import React from "react"

function TextWithLineBreaks({ text = "" }) {
  const textWithBreaks = text.split("\\n").map((text, index) => (
    <React.Fragment key={index}>
      {text}
      <br />
    </React.Fragment>
  ))

  return <>{textWithBreaks}</>
}

export default TextWithLineBreaks
